import './modal.scss';
import axios from 'axios';
import { createElementFromHTML } from '../../utilities/js/helper';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

class Modal {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-modal',
            modalAttr: 'data-modaltarget',
            clickBpAttr: 'data-modal-click-bp',
            scrollerAttr: 'scroller',
            onAjaxLoaded: null,
            onClosed: null,
            loader: null
        };

        this.settings = Object.assign({}, defaults, options);
        this.$modal = element;
        this.modalType = 'content';
        this.modalOpen = false;
        this.$modal = element;
        this.$modalTarget = null;
        this.$modalScroller = null;
        this.$solutionfinder = document.getElementById('solutionfinder');
        this.$solutionfinderBg = document.querySelector('[data-solutionfinder="bg"]');
        this.$siteheader = document.querySelector('[data-siteheader="root"]');
        this.clickBp = Number(this.$modal.getAttribute(this.settings.clickBpAttr));

        const modalId = this.$modal.getAttribute('data-modal');

        if (modalId) {
            this.$modalTarget = document.getElementById(modalId);
        }

        if (modalId === 'solutionfinder') {
            this.modalType = 'solutionfinder';
        }
    }

    initialize () {
        this.setEvents();

        if (this.$modalTarget !== null) {
            this.toggleTabIndex('hide');
        }
    }

    toggleTabIndex (toggle) {
        const $clickableItems = this.$modalTarget.querySelectorAll('button, a, input, select, textarea');
        $clickableItems.forEach(($item) => {
            if (toggle === 'hide') {
                $item.setAttribute('tabindex', -1);
            } else {
                $item.removeAttribute('tabindex');
            }
        });
    }

    setEvents () {
        this.$modal.addEventListener('click', (e) => {
            if (this.clickBp > 0) {
                if (window.innerWidth < this.clickBp) {
                    e.preventDefault();
                    this.openModal();
                }
            } else {
                e.preventDefault();
                this.openModal();
            }
        });

        if (this.$modal.getAttribute('data-modal-open') !== null) {
            window.setTimeout(() => {
                this.openModal();
            }, 500);
        }

        // close navigation on ESC key
        document.addEventListener('keydown', (e) => {
            let isEscape = false;
            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
            } else {
                isEscape = (e.keyCode === 27);
            }
            if (isEscape && this.modalOpen === true) {
                this.closeModal();
            }
        });
    }

    openModal () {
        const $modalAjaxURL = this.$modal.getAttribute('data-modal-ajax-url');

        const $currentModal = document.querySelector('.modal.is--active');
        if ($currentModal) {
            const modalAPI = $currentModal.API;
            modalAPI.closeModal();
        }

        if ($modalAjaxURL !== null) {
            this.loadModal($modalAjaxURL, () => {
                this.showModal();
            });

            return false;
        }

        if (this.modalType === 'solutionfinder') {
            this.$solutionfinderBg.classList.add('is--visible');
            this.$solutionfinder.style.visibility = 'visible';

            // 1 Day = 24 Hrs = 24*60*60 = 86400
            // 90 Day = 86400 * 90 = 7776000
            document.cookie = 'SOLUTIONFINDER=true; expires=' + (new Date(Date.now() + 7776000 * 1000)).toUTCString() + '';
        }

        if (this.$modalTarget === null) {
            const uri = this.$modal.getAttribute('href');
            this.modalType = 'ajax';

            if (uri !== null) {
                this.loadModal(uri, () => {
                    this.showModal();
                });
            }
        } else {
            this.showModal();
            this.toggleTabIndex('show');
        }
    }

    loadModal (uri, callback) {
        let $loader = null;

        if (this.settings.loader !== null) {
            $loader = createElementFromHTML(this.settings.loader);
        }

        if ($loader !== null) {
            this.$modal.appendChild($loader);
        }

        axios({
            url: uri
        })
            .then((response) => {
                if (typeof response.data.action !== 'undefined' && typeof response.data.content !== 'undefined' && response.data.action === 'render') {
                    const content = response.data.content;
                    const $content = createElementFromHTML(content);
                    document.querySelector('body').appendChild($content);
                    const modalId = $content.getAttribute('id');
                    this.$modalTarget = document.getElementById(modalId);

                    if ($loader !== null) {
                        $loader.parentNode.removeChild($loader);
                    }

                    if (typeof this.settings.onAjaxLoaded === 'function') {
                        this.settings.onAjaxLoaded(this.$modalTarget);
                    }

                    if (typeof callback === 'function') {
                        callback();
                    }
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }

    showModal () {
        this.$modalScroller = this.$modalTarget.querySelector('[' + this.settings.modalAttr + '="' + this.settings.scrollerAttr + '"]');

        if (this.modalType === 'ajax') {
            window.setTimeout(() => {
                this.$modalTarget.classList.add('is--active');
            }, 250);
        } else {
            this.$modalTarget.classList.add('is--active');
        }

        if (this.$modalTarget.closest('[data-siteheader="root"]')) {
            this.$siteheader.classList.remove('siteheader--hidden');
        }

        if (this.$modalScroller) {
            disableBodyScroll(this.$modalScroller);

            const $solutionfinder = this.$modalScroller.querySelector('[data-solutionfinder="root"]');
            if ($solutionfinder) {
                disableBodyScroll($solutionfinder);
            }
        }

        const $$exists = this.$modalTarget.querySelectorAll('.modal__exit');
        $$exists.forEach(($exit) => {
            $exit.addEventListener('click', (e) => {
                e.preventDefault();
                this.closeModal();
            });
        });

        this.modalOpen = true;
        this.$modalTarget.API = this;
    }

    closeModal () {
        this.$modalTarget.classList.remove('is--active');

        if (this.modalType === 'solutionfinder') {
            window.setTimeout(() => {
                this.$solutionfinderBg.classList.remove('is--visible');
                this.$solutionfinder.style.visibility = 'hidden';
            }, 500);
        }

        if (this.modalType === 'ajax') {
            this.$modalTarget.classList.remove('is--active');
            window.setTimeout(() => {
                this.$modalTarget.parentNode.removeChild(this.$modalTarget);
            }, 250);
        }

        if (this.$modalScroller) {
            enableBodyScroll(this.$modalScroller);

            const $solutionfinder = this.$modalScroller.querySelector('[data-solutionfinder="root"]');
            if ($solutionfinder) {
                enableBodyScroll($solutionfinder);
            }
        }

        this.modalOpen = false;

        if (typeof this.settings.onClosed === 'function') {
            this.settings.onClosed(this.$modalTarget);
        }
    }
}

export { Modal };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$modal = $context.querySelectorAll('[data-modal]');

        for (let i = 0; i < $$modal.length; i++) {
            const $modal = new Modal($$modal[i]);
            $modal.initialize();
        }
    }
});
